import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
const Layout = () => {
  return (
    <div className="d-flex flex-column flex-root">
      <Header />
      <div className="container mt-3">
        <div className="wrapper d-flex flex-column flex-row-fluid">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
